body {
  background-color: #81c62e;
  margin: 0;
}

#about {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

#about .name {
  text-align: justify;
  color: #0000;
  text-shadow: 0 0 8px #000c;
  max-width: 70vw;
  padding: 0 .5vw;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 6rem;
}

@media (width <= 768px) {
  #about .name {
    max-width: 90vw;
    padding: 0 1vw;
    font-size: 5.5rem;
  }
}

#about .name .a {
  color: #adff2f;
  text-decoration: none;
}

#about .name .b {
  color: orange;
  text-decoration: none;
}

/*# sourceMappingURL=index.04f027b4.css.map */
