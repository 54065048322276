// @import url("https://fonts.googleapis.com/css2?family=El+Messiri&display=swap");

// * {
//   // font-family: "El Messiri", sans-serif;
//   // a {
//   //   color: black;
//   //   text-decoration: none;
//   // }
// }

body {
  background-color: rgb(129, 198, 46);
  margin: 0;
  // main {
  //   padding: 4vw;
  //   height: 100vh;
  // }

  // nav {
  //   position: fixed;
  //   right: 0;
  //   width: 40vw;
  //   padding: 2vw;

  //   display: flex;
  //   justify-content: space-evenly;
  // }
}

#about {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .name {
    // font-family: "monospace";
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    max-width: 70vw;

    @media (max-width: 768px) {
      font-size: 5.5rem;
      max-width: 90vw; // Adjust max-width for smaller screens
      padding: 0 1vw; // Adjust padding for smaller screens
    }
    font-size: 6rem;
    padding: 0 0.5vw;
    color: transparent;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);

    .a {
      color: greenyellow;
      text-decoration: none;
    }
    .b {
      color: orange;
      text-decoration: none;
    }
  }
}
